<template>
  <div class="editList">
    <div style="background: #fff;padding: 30px;" class="form-w-400">
      <titleDom title="个人中心" :is-show-line="false"/>
      <el-tabs v-model="activeName" style="margin-top: 20px;">
        <el-tab-pane label="基本资料" name="first" style="padding-top: 20px; padding-bottom: 200px;">
          <el-form ref="form" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="姓名" prop="full_name">
              <el-input v-model="form.full_name" placeholder="请填写员工姓名" style="width: 400px"
                        :disabled="!isAdd"></el-input>
            </el-form-item>
            <el-form-item label="系统账号" prop="account">
              <el-input v-model="form.account" placeholder="请填写员工登录系统账号" style="width: 400px"
                        :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="工号" prop="job_num">
              <el-input v-model="form.job_num" placeholder="请填写工号" style="width: 400px" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请填写联系电话" style="width: 400px"></el-input>
            </el-form-item>
            <el-form-item label="出生日期" prop="birthday">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.birthday" class="date"
                              suffix-icon="el-icon-data"
                              value-format="yyyy-MM-dd"
                              style="width: 400px;"></el-date-picker>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="form.sex" @change="changeStatus">
                <el-radio v-for="item in dictData.sex" :label="item.id" :key="item.id"> {{ item.value }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="年龄" prop="age">
              <el-input v-model="form.age" placeholder="请填写年龄" style="width: 400px" disabled></el-input>
            </el-form-item>
            <el-form-item label="学历" prop="education">
              <el-select v-model="form.education" placeholder="请选择学历" style="width: 400px"
                         :popper-append-to-body="false">
                <el-option v-for="item in dictData.education" :label="item.value" :value="item.id"
                           :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div style="background: #fff;" class="btn_box">
                <a class="blue" href="#" @click="submit">确定</a>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="修改密码" name="second" style="padding-top: 20px;">
          <el-form ref="form2" :model="form2" :rules="rules" label-width="100px">
            <el-form-item label="旧密码" prop="old_password">
              <el-input :type="passwordType.old_password ? 'password' : 'text'" v-model="form2.old_password" placeholder="请输入旧密码"

                        style="width: 400px"><i slot="suffix" class="pointer" style="margin-right: 10px;" :class="[passwordType.old_password ? 'icon-hide-view' : 'icon-view']" @click="passwordType.old_password = !passwordType.old_password"></i></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input :type="passwordType.password ? 'password' : 'text'" v-model="form2.password" placeholder="请输入新密码"
                        style="width: 400px"><i slot="suffix" class="pointer" style="margin-right: 10px;" :class="[passwordType.password ? 'icon-hide-view' : 'icon-view']" @click="passwordType.password = !passwordType.password"></i></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="cfm_password">
              <el-input :type="passwordType.cfm_password ? 'password' : 'text'" v-model="form2.cfm_password" placeholder="请输入确认密码"
                        style="width: 400px"><i slot="suffix" class="pointer" style="margin-right: 10px;" :class="[passwordType.cfm_password ? 'icon-hide-view' : 'icon-view']" @click="passwordType.cfm_password = !passwordType.cfm_password"></i></el-input>
            </el-form-item>
            <el-form-item>
              <div style="background: #fff;" class="btn_box">
                <a class="blue" href="#" @click="submit2">确定</a>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>


    </div>


  </div>
</template>

<script>

import titleDom from "@/components/common/titleDom.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import * as auth from "@/utils/auth";

const defaultForm = {
  id: null,
  organize_id: null,
  role_id: [],
  full_name: '',
  account: '',
  password: '',
  job_num: '',
  mobile: '',
  position: '',
  birthday: '',
  sex: 1,
  age: '',
  education: '',
  job_status: 1,
  status: 1,
}

const defaultForm2 = {
  old_password: '',
  password: '',
  cfm_password: '',
}
export default {
  name: "userPanel",
  components: {Treeselect, titleDom},
  data() {
    return {
      queryID: null,
      activeName: 'first',
      passwordType: {
        password: true,
        old_password: true,
        cfm_password: true
      },
      // 选项字典data
      dictData: {
        roles: [],
        // 1=启用 2=禁用
        status: [
          {
            id: 1,
            value: '启用'
          },
          {
            id: 2,
            value: '禁用'
          }
        ],
        dept: [],
        // 1=男 2=女 3=保密
        sex: [
          {
            id: 1,
            value: '男'
          },
          {
            id: 2,
            value: '女'
          },
          {
            id: 3,
            value: '保密'
          }
        ],
        education: [
          {
            id: '博士研究生',
            value: '博士研究生'
          },
          {
            id: '硕士研究生',
            value: '硕士研究生'
          },
          {
            id: '本科',
            value: '本科'
          },
          {
            id: '专科及以下',
            value: '专科及以下'
          }
        ],
        // 1=在职在岗 2=在职离岗 3=离职
        job_status: [
          {
            id: 1,
            value: '在职在岗'
          },
          {
            id: 2,
            value: '在职离岗'
          },
          {
            id: 3,
            value: '离职'
          }
        ]
      },
      form: {
        ...defaultForm,
      },
      form2: {
        ...defaultForm2,
      },
      rules: {
        account: [
          {required: true, message: '请输入系统账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          {validator: this.validNewPass, trigger: 'blur'}
        ],
        old_password: [
          {required: true, message: '请输入旧密码', trigger: 'blur'},
        ],
        cfm_password: [
          {required: true, message: '请输入确认密码', trigger: 'blur'},
          {validator: this.validConfirmPass, trigger: 'blur'}
        ],
        role_id: [
          {required: true, message: '请选择角色', trigger: 'blur'},
        ],
        status: [
          {required: true, message: '请选择账号状态', trigger: 'blur'},
        ],
        full_name: [
          {required: true, message: '请输入员工姓名', trigger: 'blur'},
        ],
        organize_id: [
          {required: true, message: '请选择所属组织', trigger: 'blur'},
        ],
        job_num: [
          {required: true, message: '请输入工号', trigger: 'blur'},
        ],
        position: [
          {required: true, message: '请输入岗位', trigger: 'blur'},
        ],
        mobile: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {pattern: /^1[3-9]\d{9}$/, message: "请输入正确的联系电话", trigger: "blur"}
        ]
      }
    }
  },
  computed: {
    isAdd: function () {
      return !this.$route.query.id;
    }
  },
  watch: {
    'form.birthday': {
      handler: function (newVal) {
        if (newVal && newVal !== '0000-00-00 00:00:00') {
          this.form.age = this.calculateAge(newVal);
        } else {
          this.form.age = null;
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getUser(this.$store.state.userInfo.admin_id);
    this.getRoleOption();
    this.getOrganizeOption(1);
  },
  methods: {
    /**
     * 验证新密码
     */
    validNewPass(rule, value, callback) {
      let reg =  /^(?=.*[A-Za-z])(?=.*\d).{8,}$/
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (value.length < 8) {
        callback(new Error('密码长度至少为8位数'))
      } else if (!reg.test(value)) {
        callback(new Error('密码至少包含英文字母+数字'))
      } else {
        if (this.form2.cfm_password !== '') {
          this.$refs.form2.validateField('cfm_password');
        }
        callback();
      }
    },
    /**
     * 验证确认密码
     */
    validConfirmPass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form2.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },
    getRoleOption() {
      this.$api.getRoleOption().then(res => {
        const data = res.data.map(e => {
          return {
            id: e.id,
            value: e.role_name
          }
        })
        this.$set(this.dictData, 'roles', data)
      })
    },
    getOrganizeOption(type) {
      this.$api.getOrganizeTreeList(type).then(res => {
        this.$set(this.dictData, 'dept', res.data)
      })
    },
    getUser(id) {
      this.$api.getUser(id).then(res => {
        this.form = res.data;
      })
    },
    changeRoles(val) {
      console.log(val, 111)
    },
    changeStatus(val) {
      console.log(val, 111)
    },
    // 重置密码
    resetPwd() {
      this.$confirm('此操为敏感操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.resetPwd({id: this.queryID}).then(() => {
          this.$message({
            type: 'success',
            message: '重置密码成功!'
          });
          this.getDataList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码'
        });
      });
    },
    // 根据出生日期计算年龄
    calculateAge(birthDateString) {
      console.log(birthDateString, 1111)
      const today = new Date();
      const birthDate = new Date(birthDateString);
      const ageInMilliseconds = today.getTime() - birthDate.getTime();
      const ageInYears = Math.floor(ageInMilliseconds / (365 * 24 * 60 * 60 * 1000));
      return ageInYears;
    },
    // 表单重置
    reset() {
      this.form = {
        ...defaultForm,
        id: this.$store.state.userInfo.admin_id
      };
      this.resetForm("form");
    },
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$api.editUser(this.form).then(() => {
            this.$message({
              message: this.form.id ? "修改成功" : "新增成功",
              type: 'success'
            });
            // this.reset();
          });
        }
      });
    },
    submit2() {
      this.$refs["form2"].validate(valid => {
        if (valid) {
          this.$api.changePwd(this.form2).then(() => {
            this.$confirm('密码修改成功，请重新进行登录', '提示', { confirmButtonText: '确定', type: 'warning'}).then(() => {
              auth.removeToken();
              this.$router.push({path: '/login', query: {}});
              this.resetForm("form2");
            }).catch(() => {});

          });
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.editList {
  margin: 18px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  overflow: scroll;
  scrollbar-width: none;
  background: #fff;

  // 表单宽度为320px样式修改
  .form-w-320 {
    // 表单item样式修改
    ::v-deep .el-form-item.item-width-1200 {

      .el-form-item__error {
        top: 80%;
        left: 0;
      }
    }
  }

  .form-w-400 {
    // 表单item样式修改
    ::v-deep .el-form-item {
      margin-bottom: 8px;

      .el-form-item__content {
        font-size: 12px;
      }

      .el-form-item__error {
        top: 25%;
        left: 402px;
      }
    }
  }

  ::v-deep .vue-treeselect {
    height: 34px;
    line-height: 34px;
    background: #FFFFFF;
    border-radius: 4px;

    .vue-treeselect {
      border: 1px solid #CEDAE0;
    }
  }

  .resetPwd {
    width: 48px;
    height: 11px;
    font-weight: 400;
    font-size: 12px;
    color: #3984F5;
    line-height: 36px;
    position: relative;
    left: 12px;
    cursor: pointer;
  }

  .itemBox {
    margin-top: 28px;
  }

  .column2 {

    > div {
      height: 41px;
      float: left;
      margin-bottom: 8px;
    }

    ::v-deep .el-form-item {
      width: 650px;
    }

  }

  .btn_box {
    margin-top: 18px;

    a {
      display: inline-block;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #EEF1F3;
      border-radius: 4px;
      margin-right: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #6C7880;
    }

    .blue {
      background: #3984F5;
      color: #fff;
    }
  }
}
</style>